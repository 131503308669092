export const CategoryColors = [
  { color: "#EF2139", bgColor: "#FFE9E9" },
  { color: "#4A89E8", bgColor: "#D6E6FF" },
  { color: "#8B79F9", bgColor: "#E8E4FF" },
  { color: "#0CC773", bgColor: "#E0FFF1" },
  { color: "#EC8050", bgColor: "#FFEEE7" },

  { color: "#FF7C00", bgColor: "#FDDCC5" },
  { color: "#2793ff", bgColor: "#EFF5FB" },
  { color: "#c00", bgColor: "#F7EFF4" },
  { color: "#46c5cc", bgColor: "#dbfdff" },
  { color: "#b8ae00", bgColor: "#FEFBC3" },
  { color: "#1bd1d4", bgColor: "#c7feff" }
];

export const CategoryConfig = {
  'yachizhiliao': {
    category: '牙齿治疗',
    banners: [
      { title: '来查查你的牙齿有什么问题？', btn: '立即报名' },
      { title: '本地牙齿种植价格表', btn: '立即查看' },
      { title: '口腔医院排行榜', btn: '登录查看' },
      { title: '免费连线专家医生问诊', btn: '立即报名 ' },
    ],
  },
  'shilijiancha': {
    category: '视力检查',
    banners: [
      { title: '免费在线测视力', btn: '立即测试' },
      { title: '附近的眼科医院正在做活动', btn: '立即查看' },
      { title: '报名免费领取眼科医院优惠券', btn: '立即报名' },
    ],
  },
  'toufazhongzhi': {
    category: '头发种植',
    banners: [
      { title: '植发医院排行榜', btn: '登录查看' },
      { title: '免费连线专家咨询植发价格', btn: '登录报名' },
      { title: '本地植发价格表', btn: '登录查看' },
    ],
  },
  'yingyupeixun': {
    category: '英语培训',
    banners: [
      { title: '本地英语学校排行榜', btn: '登录查看' },
      { title: '登录领取英语培训课程安排', btn: '登录领取' },
    ],
  },
  'lvyou': {
    category: '旅游',
    banners: [
      { title: '免费领取旅游景点团购价格', btn: '登录领取' },
      { title: '联系我们，报名跟团游', btn: '立即登录' },
    ],
  },
  'liuxueshenqing': {
    category: '留学申请',
  },
  'jiatingzhuangxiu': {
    category: '家庭装修',
    banners: [
      { title: '近期装修节大促，报名参加', btn: '立即报名' },
      { title: '免费室内装修设计，电话咨询', btn: '立即报名' },
      { title: 'AI+设计师的最新设计，报名拿资料', btn: '立即报名' },
    ],
  },
  'jianshenyangshengpeixun': {
    category: '健身养生培训',
  },
  'jiashipeixun': {
    category: '驾驶培训',
  },
  'pifumeirong': {
    category: '皮肤美容',
  },
  'meitisuxing': {
    category: '美体塑形',
  },
  'yankemeirong': {
    category: '眼科美容',
  },
  'shilijiaozheng': {
    category: '视力矫正',
  },
};
