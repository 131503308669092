import type { RouteRecordRaw } from "vue-router";

const HomeTabPage = {
  HomeDaily: "Home",
  HomeSpecial: "HomeSpecial"
};

const getRedirect = function () {
  if (!process.env.VUE_APP_START) return "/home";
  else if (process.env.VUE_APP_START == "guide") return "/guide";
  else return "/home";
};

export default <RouteRecordRaw[]>[
  {
    path: "/",
    redirect: getRedirect()
  },
  {
    path: "/guide",
    name: "Guide",
    component: () => import("@/views/guide/GuideView.vue"),
    meta: {
      title: APP_NAME,
      hideFixedHome: false,
      spaceKeys: ["Guide_banner", "Guide_native"]
    }
  },
  {
    path: "/home",
    redirect: "/home/daily",
    component: () => import(/* webpackPrefetch: true  */ "@/views/home/HomeView.vue"),
    meta: {
      title: APP_NAME,
      hideFixedHome: false
    },
    children: [
      {
        path: "/home/daily",
        name: HomeTabPage.HomeDaily,
        component: () => import(/* webpackPrefetch: true  */ "@/views/home/tab-page/HomeDailyView.vue"),
        meta: {
          title: "知识日报",
          spaceKeys: ["HomeDialog", "HomeInter", "HomeBanner", "HomeNative"]
        }
      },
      {
        path: "/home/special",
        name: HomeTabPage.HomeSpecial,
        component: () => import(/* webpackPrefetch: true  */ "@/views/home/tab-page/HomeSpecialView.vue"),
        meta: {
          title: "专题分类",
          spaceKeys: ["HomeDialog", "HomeInter", "HomeBanner", "HomeNative"]
        }
      }
    ]
  },
  {
    path: "/category",
    name: "Category",
    component: () => import(/* webpackPrefetch: true  */ "@/views/category/CategoryView.vue"),
    meta: {
      title: "全部学科分类",
      backHomeName: HomeTabPage.HomeSpecial,
      spaceKeys: ["CategoryBanner"]
    }
  },
  {
    path: "/articleList",
    name: "ArticleList",
    component: () => import(/* webpackPrefetch: true  */ "@/views/article/ArticleListView.vue"),
    props: (route) => ({ category: route.query.category }),
    meta: {
      title: "文章列表",
      backHomeName: HomeTabPage.HomeSpecial,
      spaceKeys: ["ArticleListNative", "ArticleListBanner"]
    }
  },
  {
    path: "/articleDetail",
    name: "ArticleDetail",
    component: () => import(/* webpackPrefetch: true  */ "@/views/article/ArticleDetailView.vue"),
    props: (route) => ({ id: route.query.id }),
    meta: {
      title: "文章详情",
      backHomeName: HomeTabPage.HomeDaily,
      spaceKeys: ["ArticleDetailNative", "ArticleDetailBanner", "ArticleDetailInter"]
    }
  },
  {
    path: "/topicKepu",
    name: "TopicKepu",
    component: () => import(/* webpackPrefetch: true  */ "@/views/topic/TopicKepuView.vue"),
    meta: {
      title: "科普挑战",
      spaceKeys: ["TopicKepuNative", "TopicKepuBanner", "TopicKepuDialog", "TopicKepuInter"]
    }
  },
  {
    path: "/topicTrivia",
    name: "TopicTrivia",
    component: () => import(/* webpackPrefetch: true  */ "@/views/topic/TopicTriviaView.vue"),
    props: (route) => ({
      answer: (route.params.first ? undefined : window.history.state.answer) || route.query.answer,
      topic: route.params.first ? undefined : window.history.state.topic,
      id: route.query.id
    }),
    meta: {
      title: "冷知识判断",
      spaceKeys: ["TopicTriviaNative", "TopicTriviaBanner", "TopicTriviaDialog"]
    }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/NotFoundView.vue"),
    meta: {
      title: "404"
    }
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404"
  }
];
