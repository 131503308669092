const inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6924029",
    space_js: "//gflpjby.gnpad.cn/common/kfe/source/n/static/u-blu-n.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6924030",
    space_js: "//gflpjby.gnpad.cn/production/cx/wfm/source/t/dp/openjs/d.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6924031",
    space_js: "//gflpjby.gnpad.cn/source/dy/xgn_ue/qh.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6924032",
    space_js: "//gflpjby.gnpad.cn/source/ez/static/y/common/hov-f-ro.js"
  }
];

const Native4 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6924033",
    space_js: "//gflpjby.gnpad.cn/production/fazip/w_g/ss.js"
  }
];

const Native5 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6924034",
    space_js: "//gflpjby.gnpad.cn/production/g_b/common/aj/qxh_tx.js"
  }
];

export default {
  HomeInter: inter,
  HomeDialog: Native1,
  HomeNativeInter: Native1,
  HomeBanner: Native3,
  HomeNative: Native2,
  HomeNativeCenter: Native4,
  HomeNativeBottom: Native5,
  ArticleListNative: Native2,
  ArticleListBanner: Native3,
  ArticleDetailNative: Native2,
  ArticleDetailNativeCenter: Native4,
  ArticleDetailNativeBottom: Native5,
  ArticleDetailBanner: Native3,
  ArticleDetailInter: inter,
  CategoryBanner: Native3,
  TopicTriviaNative: Native2,
  TopicTriviaBanner: Native3,
  TopicTriviaDialog: Native1,
  TopicKepuNative: Native2,
  TopicKepuBanner: Native3,
  TopicKepuDialog: Native1,
  TopicKepuInter: inter
};
