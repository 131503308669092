import { request } from "@/utils/service";
import { useUserStore } from "@/store/user";

function baseParams(isGzp: boolean = true) {
  const { token, uuid } = useUserStore();
  const params: IBaseParams = { user_id: uuid };

  if (token) params.access_token = token;

  // if (isGzp) params.gzp = 1;

  return params;
}

export const getArticleList = (params: IArticleParams) => {
  return request<ListData<IArticle>>({
    url: "/api/article/list",
    method: "post",
    data: { ...baseParams(), ...params }
  });
};

export const getArticleRecList = (params: WithRequiredProperty<IArticleParams, "id">) => {
  return request<ListData<IArticle>>({
    url: "/api/article/reclist",
    method: "post",
    data: { ...baseParams(), ...params }
  });
};

export const getCategoryList = () => {
  return request<ListData<ICategory>>({
    url: "/api/category/list",
    method: "post"
  });
};

export const getArticleSubjectList = () => {
  return request<ISpecial[]>({
    url: "/api/article/subjectlist",
    method: "post",
    data: { ...baseParams() }
  });
};

interface IUserObjectParams {
  object_id: string;
  object_type: string;
  type: "0" | "1";
}
export const userobjectcollectAdd = (params: IUserObjectParams) => {
  return request<boolean>({
    url: "/api/userobjectcollect/add",
    method: "post",
    data: { ...baseParams(), ...params }
  });
};

export const userobjectlikeAdd = (params: IUserObjectParams) => {
  return request<boolean>({
    url: "/api/userobjectlike/add",
    method: "post",
    data: { ...baseParams(), ...params }
  });
};
