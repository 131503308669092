import { defineStore } from "pinia";
import { nextTick } from "vue";

interface IControlState {
  // 记录答题数出插屏
  answer: number;
  // 首页插屏 是否展示过
  interShown: boolean;
  // 进入详情页次数
  enterDetailCount: number;
  // 根据是否点击再来一组 展示科普详情页插屏
  kepuInter: boolean;
}

export const useControlStore = defineStore({
  id: "control",
  state(): IControlState {
    return {
      interShown: false,
      answer: 0,
      enterDetailCount: 0,
      kepuInter: false
    };
  },
  getters: {
    // 是否展示详情页插屏
    showDetailInter(): boolean {
      return this.enterDetailCount > 1;
    },
    // 是否展示答题插屏
    showAnswerInter(): boolean {
      return this.answer != 0 && this.answer % 3 == 0;
    }
  },
  actions: {
    addAnswer() {
      this.answer++;
    },
    setInterShown(flag: boolean) {
      this.interShown = flag;
    },
    addEnterDetailCount() {
      this.enterDetailCount++;
    },
    showKepuInter() {
      this.kepuInter = false;
      nextTick(() => {
        this.kepuInter = true;
      });
    }
  }
  // persist: [
  //   {
  //     storage: window.sessionStorage,
  //     paths: ['enterDetailCount']
  //   }
  // ]
});
