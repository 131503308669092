import { defineStore } from "pinia";
import { getCategoryList } from "@/apis/article";
import { CategoryColors } from "@/utils/constant";

type C_ICategory = ICategory & { tagColor: { bg: string; color: string } };

interface ICategoryState {
  category: C_ICategory[];
}

export const useCategoryStore = defineStore({
  id: "category",
  state: (): ICategoryState => {
    return {
      category: []
    };
  },
  getters: {
    // 分类map数据
    categoryMap(): { [key: string]: C_ICategory } {
      return this.category.reduce((prev, curr) => {
        prev[curr.category] = curr;
        return prev;
      }, {} as { [key: string]: C_ICategory });
    }
  },
  actions: {
    // 获取分类数据
    async getCategory() {
      try {
        const { data } = await getCategoryList();

        if (data?.length) {
          this.category = data.map((item, index) => {
            const temp = CategoryColors[index % CategoryColors.length];
            return Object.assign(item, { tagColor: { bg: temp.bgColor, color: temp.color } });
          });
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
});
